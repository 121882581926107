import React from "react"
import { AccordionButton, AccordionItem, AccordionPanel, Box, Heading } from "@chakra-ui/react"

import { useContent } from "@app/hooks/useContent"
import { Icon } from "@app/components/Icon"
import { AccordionBlock } from "@app/components/Sections/ProductAccordion/ProductAccordion"

type Props = {
  accordionBlock: AccordionBlock
}

const ProductAccordionBlock: React.FC<Props> = ({ accordionBlock }) => {
  const title = accordionBlock.title
  const content = useContent(accordionBlock)

  return (
    <AccordionItem borderColor="border.default" _last={{ borderBottom: "0" }}>
      <AccordionButton
        d="block"
        py={{ base: "4", lg: "6" }}
        px="0"
        _hover={{ background: "none" }}
        _focus={{ boxShadow: "none" }}
        _expanded={{ ".chakra-heading > span": { transform: "rotate(-180deg)" } }}
      >
        <Heading size="h5" d="flex" justifyContent="space-between" alignItems="center">
          <Box>{title}</Box>

          <Box as="span" w={{ base: "4" }} h={{ base: "4" }} transition="transform 0.2s ease">
            <Icon name="arrows/chevron-down" width="100%" height="100%" />
          </Box>
        </Heading>
      </AccordionButton>

      <AccordionPanel
        px="0"
        pt="0"
        pb={{ base: "5", lg: "6" }}
        sx={{ ".rte > *": { mb: "4" }, ".rte > *:last-child": { mb: "0" }, a: { textDecor: "underline" } }}
      >
        {content}
      </AccordionPanel>
    </AccordionItem>
  )
}

export default React.memo(ProductAccordionBlock)
