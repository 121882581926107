import React, { useEffect, useMemo, useRef } from "react"
import { Accordion, Box, Container, Heading } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import ProductAccordionBlock from "@app/components/Product/ProductAccordionBlock"
import { AnalyticProps, withSection } from "@app/hoc/Section"

import type { ProductTemplateSectionProps } from "@app/components/Product/Product"

export type AccordionBlock = {
  active: boolean
  title: string
  content: any[]
}

type Props = GatsbyTypes.SanitySectionProductAccordion & AnalyticProps & ProductTemplateSectionProps
const ProductAccordion: React.FC<Props> = ({
  menuTitle,
  accordionTitle: title,
  accordionBlocks: sanitySlides,
  sanityProduct,
  innerRef,
  tag,
}) => {
  const { saveProductItems } = useAppContext()

  // Combine accordion slides
  const accordionBlocks = useMemo(() => {
    const slideDataConversion = (slide: any) => ({
      active: slide?.active as boolean,
      title: slide?.title as string,
      content: slide?.content as unknown as any[],
    })
    const productSlides = sanityProduct?.accordionBlocks?.map(slideDataConversion)
    const templateSlides = sanitySlides?.map(slideDataConversion)
    const slides: AccordionBlock[] = []
    if (productSlides?.length) slides.push(...productSlides)
    if (templateSlides?.length) slides.push(...templateSlides)

    return slides
  }, [sanityProduct?.accordionBlocks, sanitySlides])

  const sectionRef = useRef<HTMLDivElement>(null)

  useEffect(() => saveProductItems(menuTitle, sectionRef), [sectionRef, saveProductItems, menuTitle])

  return accordionBlocks.length ? (
    <Box as="section" bg="background.grey100" ref={sectionRef}>
      <Container maxW="container.2xl" pt={{ lg: "7.5" }} pb={{ lg: "18" }} px={{ lg: "20" }}>
        <Box
          ref={innerRef}
          py={{ base: "8", lg: "12" }}
          px={{ base: "4", lg: "10" }}
          borderRadius={{ lg: "xl" }}
          boxShadow={{ lg: "subtleBottomGlow" }}
          bg="background.white"
        >
          {title && (
            <Heading as={tag} size="h4" mb={{ base: "5", lg: "6" }}>
              {title}
            </Heading>
          )}
          <Accordion allowToggle defaultIndex={0}>
            {accordionBlocks.map(slide => (
              <ProductAccordionBlock key={slide.title.toLowerCase()} accordionBlock={slide} />
            ))}
          </Accordion>
        </Box>
      </Container>
    </Box>
  ) : null
}

export default React.memo(withSection(ProductAccordion))
